




























































































































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { downloadJSON } from '@/utils/fileUtils.ts'

@Component({})
export default class StockBalanceAdjustmentsReport extends Vue {
  private filters = {
    productId: null as string | null,
    fromDate: null as string | null,
    toDate: null as string | null,
  }

  private fromDateMenu = false
  private toDateMenu = false
  private exportJsonLoading = false
  private stockBalanceAdjustmentsData = null

  // Table headers defined in script
  private tableHeaders = [
    { text: this.$t('c:stock-details:ID'), value: 'id' },
    { text: this.$t('c:stock-details:Cost account'), value: 'costAccount' },
    { text: this.$t('c:stock-details:Cost center code'), value: 'costCenterCode' },
    { text: this.$t('c:stock-details:Cost center name'), value: 'costCenterName' },
    { text: this.$t('c:stock-details:Date'), value: 'date' },
    { text: this.$t('c:stock-details:Performed by'), value: 'performedBy' },
    { text: this.$t('c:stock-details:Product cost'), value: 'productCost' },
    { text: this.$t('c:stock-details:Product ID'), value: 'productId' },
    { text: this.$t('c:stock-details:Quantity'), value: 'quantity' },
    { text: this.$t('c:stock-details:Reason'), value: 'reasonText' },
    { text: this.$t('c:stock-details:Sum product amount'), value: 'sumProductAmount' },
  ]

  private executeReport() {
    this.$axios
      .get('/v4/site/stock/balance-adjustments', { params: this.filters })
      .then((response) => {
        this.stockBalanceAdjustmentsData = response.data.data[0]
      })
      .catch((error) => {
        console.error('Error fetching report:', error)
      })
  }

  private clear() {
    this.filters = {
      productId: null,
      fromDate: null,
      toDate: null,
    }
    this.fromDateMenu = false
    this.toDateMenu = false
    this.stockBalanceAdjustmentsData = null
  }

  private handleJsonDownload() {
    downloadJSON(this.stockBalanceAdjustmentsData, 'stock_balance_adjustments', this.setExportJsonLoading)
  }

  private setExportJsonLoading(loading: boolean): void {
    this.exportJsonLoading = loading
  }
}
